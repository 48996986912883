import React, { useState, createContext, useContext } from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import Welcome from "./Components/Welcome/Welcome.jsx";

const breakpoints = {
  sm: '320px', // Small devices (landscape phones, 320px and up)
  md: '768px', // Medium devices (tablets, 768px and up)
  lg: '1024px', // Large devices (desktops, 1024px and up)
  xl: '1200px', // Extra large devices (large desktops, 1200px and up)
};
//Set a Default Koii Theme
//This is underutilized for now
const theme = extendTheme({
  breakpoints,
  fonts: {
    body: "Sora", // Set Sora as the default font for the body
  },
  colors: {
    koiiBlue: "#171753",
    koiiWhite: "#ecfffe",
    koiiMint: "#5ed9d1",
    koiiGold: "#FFEE81",
    koiiBlack: "black",
  },
  styles: {
    global: {
      body: {
        color: "white", // Set the default text color to white
      },
    },
  },
});

// Create a Context that is used for the Welcome button
export const WelcomeContext = createContext();

function App() {
  const [welcomeMode, setWelcomeMode] = useState(true);

  return (
    <ChakraProvider theme={theme}>
      {/*      This context is grandfathered in from the original route-based version, I am not removing it for now
       */}
      <WelcomeContext.Provider value={{ welcomeMode, setWelcomeMode }}>
        <Welcome />
      </WelcomeContext.Provider>
    </ChakraProvider>
  );
}

export default App;
