//ENUMS
//const URL = "http://localhost:8000";
const URL = "https://leaderboard-api.koii.network";

//fetches data from the server
//returns an object with the data
export async function fetchMasterData() {
  //fetches data from the server
  const fetchData = async (url) => {
    const response = await fetch(url);
    const data = await response.json();
    return data;
  };

  const fetchDataPost = async (url) => {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        taskId: "4cj2aLZ7dGrsL4jm7b5bEzEKrYMoJzy8Juc2fWwLZrpW",
      }),
    });
    const data = await response.json();
    return data;
  };

  //dataCache is an object that will hold all the data
  //it is structured like this:
  let dataCache = {
    timeleft: {
      hourly: {},
      weekly: {},
      twelvehourly: {},
    },
    winners: {
      hourly: {},
      weekly: {},
      twelvehourly: {},
    },
    leaderboard: {
      hourly: [],
      weekly: [],
      twelvehourly: [],
    },
  };

  const endpoints = {
    timeleft: ["hourly", "weekly", "12hourly"],
    winners: ["hourly", "weekly", "12hourly"],
    leaderboard: ["hourly", "weekly", "12hourly"],
  };

  for (let category in endpoints) {
    for (let timePeriod of endpoints[category]) {
      try {
        let data;
        if (category === "timeleft") {
          data = await fetchData(`${URL}/${category}/${timePeriod}`);
        } else {
          data = await fetchDataPost(`${URL}/${category}/${timePeriod}`);
        }

        dataCache = {
          ...dataCache,
          [category]: {
            ...dataCache[category],
            [timePeriod === "12hourly" ? "twelvehourly" : timePeriod]: data,
          },
        };
      } catch (error) {
        console.error("Error:", error);
      }
    }
  }

  const updatedDataCache = {
    ...dataCache,
    clientCounter: {
      hourly:
        dataCache.timeleft.hourly.message.days * 24 * 60 * 60 +
        dataCache.timeleft.hourly.message.hours * 60 * 60 +
        dataCache.timeleft.hourly.message.minutes * 60 +
        dataCache.timeleft.hourly.message.seconds,
      twelvehourly:
        dataCache.timeleft.twelvehourly.message.days * 24 * 60 * 60 +
        dataCache.timeleft.twelvehourly.message.hours * 60 * 60 +
        dataCache.timeleft.twelvehourly.message.minutes * 60 +
        dataCache.timeleft.twelvehourly.message.seconds,
      weekly:
        dataCache.timeleft.weekly.message.days * 24 * 60 * 60 +
        dataCache.timeleft.weekly.message.hours * 60 * 60 +
        dataCache.timeleft.weekly.message.minutes * 60 +
        dataCache.timeleft.weekly.message.seconds,
    },
  };

  /*  console.log(updatedDataCache); */

  return updatedDataCache;
}

export async function fetchNewData() {
  try {
    // Fetching data from the local server
    const response = await fetch(
      "http://localhost:8000/data" // TODO: use env file
    );

    // Check if the response status is OK
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Parsing the fetched data as JSON
    const newData = await response.json();

    return newData;
  } catch (error) {
    console.error("Error:", error);
  }
}
export function prettyTime(seconds) {
  const units = [
    { label: ["Day", "Days"], value: 3600 * 24 },
    { label: ["Hour", "Hours"], value: 3600 },
    { label: ["Minute", "Minutes"], value: 60 },
    { label: ["Second", "Seconds"], value: 1 },
  ];

  let result = [];

  for (let unit of units) {
    if (seconds >= unit.value || result.length > 0) {
      const quantity = Math.floor(seconds / unit.value);
      seconds -= quantity * unit.value;

      if (quantity > 0) {
        result.push(`${quantity} ${unit.label[quantity === 1 ? 0 : 1]}`);
      }

      if (result.length === 3) {
        break;
      }
    }
  }

  return result.join(", ");
}

export function prettyTimeTitle(seconds) {
  let hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  let minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  // Padding the hours, minutes, and seconds to always have two digits
  hours = hours.toString().padStart(2, "0");
  minutes = minutes.toString().padStart(2, "0");
  seconds = seconds.toString().padStart(2, "0");

  return `${minutes}:${seconds}`;
}

export function prettyStakeId(stakeID, length) {
  //Take a string, return a new string that will include the first 5 characters of that string, followed by 3 dots, followed by the last 5 characters of that string
  //And add safeguards, if null or undefined return "N/A"
  if (stakeID === null || stakeID === undefined) {
    return "Winner not yet chosen";
  }
  if(length==="short"){
    return stakeID.slice(0, 5)+ " . . . ";
  }else{
    return stakeID.slice(0, 5)+ " . . . "+ stakeID.slice(-5);
  }
  
}

export const handleStakeIdClick = (id) => {
  const url = `https://explorer.koii.live/address/${id}`;
  window.open(url, "_blank");
};

export function positionFinder(cacheToSearch, stakeID) {
  // Search the results array for the stakeID
  let resultsArray = cacheToSearch.results;
  for (let i = 0; i < resultsArray.length; i++) {
    if (resultsArray[i].id === stakeID) {
      return resultsArray[i];
    }
  }

  // If we get here, the stakeID was not found
  return null;
}
