//The name "Welcome" was grandfathered-in from the original codebase.
//In this build, it is a single-page application without any routes.
//I am keeping the name "Welcome" for now, but it should be renamed to "App" or "Main" in the future if we are not going to add any routes.

//Imports
import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Container,
  Center,
  Image,
  Flex,
  Spinner,
  Heading,
  Button,
  VStack,
  HStack,
} from "@chakra-ui/react";

import bottomDecoration from "./images/bottomDecoration.svg";

//Image Imports
import welcomeBackground from "./images/welcomeBackground.svg";
import welcomeBackgroundLayer2 from "./images/welcomeBackgroundLayer2.svg";

import welcomepageTopright from "./images/welcomepageTopright.svg";
import welcomeMachines from "./images/welcomeMachines.svg";

import winnerStar from "./images/winnerStar.svg";
import winnerStar2 from "./images/winnerStar2.svg";

import pirateship from "./images/pship.svg";

import singleKoii from "./images/singleKoii.gif";

//Component & Function Imports
import GameArea from "./GameArea.jsx";
import StatusArea from "./StatusArea.jsx";

import FirstLeaderboard from "./FirstLeaderboard.jsx";
import {
  fetchNewData,
  prettyTime,
} from "./helperfunctions.js";

import "./animations.css";

function Welcome() {
  // Checks if the fetch is complete, if it is, render the welcome page.
  const [localCache, setLocalCache] = useState(null);

  //Used for the animation
  const [coins, setCoins] = useState([]);
  const [coinAnimationGreenlight, setCoinAnimationGreenlight] = useState(false);

  const addCoin = () => {
    setCoins((prevCoins) => [
      ...prevCoins,
      {
        id: Math.random(),
        left: `${Math.random() * 100}vw`,
        animDelay: `${Math.random() * 5 + 1}s`,
      },
    ]);

    // Generate a random number between 0 and 1
    const randomNum = Math.random();

    // Check if the random number is less than or equal to 0.9 (90% chance)
    if (randomNum <= 0.95) {
      // Call the addCoin function again after 0.5 seconds
      setTimeout(addCoin, 1);
    } else {
      setCoinAnimationGreenlight(true);
    }
  };

  useEffect(() => {
    // DataCache is the main object that contains all the data we need to render the welcome page.
    const fetchData = async () => {
      try {
        /*         const masterData = await fetchMasterData(); */
        const newData = await fetchNewData();

        setLocalCache(newData);
        /*         setDataCache(masterData);
         */

        // Fetching data is completed, start countdown after 1 second
      } catch (error) {
        console.error("Error fetching master data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {coins.length > 0 &&
        coinAnimationGreenlight &&
        coins.map((coin) => (
          <Image
            key={coin.id}
            className="coin"
            style={{
              left: coin.left,
              animationDelay: coin.animDelay,
            }}
            src={singleKoii}
            alt="coin"
          />
        ))}

      {/*     A conditional render that checks if the fetch is complete*/}

      {localCache && localCache ? (
        //Our background images are split into two layers
        //Base layer
        <Box
          sx={{
            backgroundImage: `url(${welcomeBackground})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* //Second layer */}
          <Box
            sx={{
              backgroundImage: `url(${welcomeBackgroundLayer2})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: ["top", "center calc(100%)"],
            }}
          >
            <Container maxW={["full", "5xl"]}>
              {/* //Top right decorative image */}
              <Box position="absolute" top={[0, -10]} right={0}>
                <Image
                  width={["0", "100%"]}
                  src={welcomepageTopright}
                  alt="Welcome Page Top Right"
                />
              </Box>

              {/* //Hype section */}

              <Center pt={5} display={"flex"} flexDirection={"column"}>
                <Heading
                  color={"#BEF0ED"}
                  fontSize={{base:"40px", md: "48px", lg:"60px"}}
                  mt={5}
                  letterSpacing={"0.01em"}
                  _hover={{
                    transform: "rotateY(20deg) rotateX(20deg)",
                    cursor: "pointer",
                  }}
                  transition="transform 0.5s"
                  onClick={() =>
                    window.open("https://www.koii.network/node", "_blank")
                  }
                  zIndex={2}
                >
                  Koii Leaderboard
                </Heading>

                <Text
                  fontSize={["15px", "18px", "24px"]}
                  color={"#FFEE81"}
                  fontStyle="semibold"
                  perspective="-800px"
                  _hover={{
                    transform: "rotateY(20deg) rotateX(20deg)",
                    cursor: "pointer",
                  }}
                  transition="transform 0.4s"
                  zIndex={2}
                >
                  500 KOII Every Day!
                </Text>

                <Text
                  fontSize={["18px", "24px"]}
                  fontWeight="600"
                  fontStyle={"normal"}
                  lineHeight={["36px", "72px"]}
                  color={"#BEF0ED"}
                  transition="transform 0.4s"
                  perspective="-800px"
                  _hover={{
                    transform: "rotateY(20deg) rotateX(20deg)",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open("https://www.koii.network/node", "_blank")
                  }
                  mt={5}
                >
                  Next reward in
                </Text>

                <Text
                  mt={-8}
                  fontSize={["15px","18px", "24px"]}
                  fontWeight="600"
                  fontStyle={"normal"}
                  lineHeight={["72px"]}
                  color={"#5ED9D1"}
                  transition="transform 0.4s"
                  perspective="800px"
                  onClick={() =>
                    window.open("https://www.koii.network/node", "_blank")
                  }
                  _hover={{
                    transform: "rotateY(20deg) rotateX(20deg)",
                    cursor: "pointer",
                  }}
                >
                  {prettyTime(localCache.metadata.timeLeft)}
                </Text>
              </Center>

              <Flex 
              direction={{ base: 'column', md: 'row' }} gap={4} m={5}>
                <Box mt={5} w={{ base: '100%', md: '50%' }}>
                  <Center>
                    <Image
                      onClick={addCoin}
                      width="100%"
                      src={welcomeMachines}
                      _hover={{
                        cursor: "pointer",
                      }}
                      transition="transform 0.4s"
                    ></Image>
                  </Center>

                  <Flex
                    align="center"
                    flexDirection="column"
                    justify="center"
                    textAlign="center"
                  >
                    <Text
                      fontSize={["md", "xl"]}
                      mt={5}
                      mb={3}
                      textAlign={"left"}
                    >
                      Use your device to power a revolutionary, global computer.{" "}
                      Cut out the middle-man and{" "}
                      <Text as="span" color={"#5ED9D1"}>
                        earn rewards.
                      </Text>
                    </Text>

                    <Text
                      fontFamily={"SourceSans3"}
                      width={"100%"}
                      textAlign={"left"}
                      fontSize={["md", "15px"]}
                    >
                      In order to participate, you must{" "}
                      <Text
                        as="span"
                        color={"#5ED9D1"}
                        _hover={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          window.open("https://www.koii.network/node", "_blank")
                        }
                      >
                        download and run the Koii Desktop Node. {""}
                      </Text>
                      With Koii, while running a task, every submission will
                      make you eligible for rewards.
                    </Text>
                  </Flex>
                </Box>
                <Box
                  mt={10}
                  w={{ base: '100%', md: '60%' }}
                  padding={5}
                  borderRadius={30}
                >
                  <Box>
                    <Box
                      display="none"
                      bg="#6B5FA5"
                      borderRadius="lg"
                      p={["3", "5"]}
                      mb={5}
                    >
                      <Flex justify="space-between" color="#FFEE81" mb="4">
                        <Text fontSize={["md", "l"]} fontWeight={"semibold"}>
                          Bonus
                        </Text>

                        <Text fontSize={["md", "l"]}>
                          Top 2 task-runners will get a bonus!
                        </Text>
                      </Flex>

                      <Box
                        borderRadius="lg"
                        border="2px solid #F7E154"
                        p="3"
                        mb="2"
                      >
                        <Flex align="center" color="#F7E154">
                          {/* this allows me to render the star image relatively without taking any space  */}
                          <Box pos={"absolute"}>
                            <Image
                              src={winnerStar}
                              pos="relative"
                              left="-32px"
                              top={"0px"}
                            />
                          </Box>
                          <Text ml={3} fontWeight={"semibold"}>
                            Winner #1
                          </Text>

                          <Text m={"auto"} color="#F7E154">
                            {"Will be selected this month."}
                          </Text>
                        </Flex>
                      </Box>

                      <Box borderRadius="lg" border="2px solid #BEF0ED" p="3">
                        <Flex align="center" color="#BEF0ED">
                          <Box pos={"absolute"}>
                            <Image
                              src={winnerStar2}
                              pos="relative"
                              left="-32px"
                              top={"0px"}
                            />
                          </Box>
                          <Text ml={3} fontWeight={"semibold"}>
                            Winner #2
                          </Text>
                          <Text m={"auto"} color="#BEF0ED">
                            {"Will be selected this month."}
                          </Text>
                        </Flex>
                      </Box>
                    </Box>
                    <FirstLeaderboard localCache={localCache} />
                  </Box>
                </Box>
              </Flex>

              {/* A lazy way to fix the second background box getting cut earlier than intended, as the previous code was commented */}
              <Box pb={"13rem"}></Box>
            </Container>
          </Box>

          {/* //This component includes everything within the teal zone */}
          <GameArea localCache={localCache} />
          <StatusArea localCache={localCache} />

          <Box>
            <Center
              position="relative"
              mt={10}
              w={"80%"}
              mr={"auto"}
              ml={"auto"}
            >
            </Center>
            <Text color={"red"}>{``}</Text>
            <Center>
            </Center>
            <Image
              m="auto"
              mt={["0", "-120"]}
              minW={"100%"}
              src={bottomDecoration}
            />
          </Box>
        </Box>
      ) : (
        //If for any reason, fetch is taking time, show a loading screen
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height="100vh"
          color="white"
          backgroundColor={"#353570"}
        >
          <Box className="loadingship">
            <Center>
              <Image maxWidth="30%" src={pirateship}></Image>
            </Center>
            <Center>
              <Text fontSize={20}>Loading all the pirates...</Text>
            </Center>
          </Box>
        </Box>
      )}
    </>
  );
}

export default Welcome;
