import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Center,
  Image,
  Flex,
  Img,
  VStack,
} from "@chakra-ui/react";
import { SearchIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  prettyTime,
  prettyStakeId,
  handleStakeIdClick,
  positionFinder,
} from "./helperfunctions.js";

//Image imports
import piratehat from "./images/piratehat.svg";
import rewardCup from "./images/rewardCup.svg";
import miniGears from "./images/miniGears.svg";

import incentivizedTitleDecor from "./images/incentivizedTitleDecor.svg";
import koiiSearchIcon from "./images/koiiSearch.svg";

import gametype_1 from "./images/gametype_1.svg";
import gametype_2 from "./images/gametype_2.svg";
import gametype_3 from "./images/gametype_3.svg";

const GameArea = ({ localCache }) => {
  //Used for the game type buttons
  const [activeDataCache, setActiveDataCache] = useState(localCache);
  const [selectedTask, setSelectedTask] = useState("imatask");
  const [selectedBox, setSelectedBox] = useState(-1);
  const [amountOfTickets, setAmountOfTickets] = useState(null);

  return (
    <>
      {/*    First section: everything that is shown before the graphs
       */}
      <Box color="#353570" width={["100%", "80%"]} m="auto" mt={5}>
        <Flex>
          <Image w={["0%", "20%"]} src={incentivizedTitleDecor}></Image>
          <Heading
            textAlign="center"
            fontSize="5xl"
            letterSpacing={"-0.96px"}
            fontWeight={600}
            w={["98%", "40%"]}
            m={"auto"}
            fontFamily={"Sora"}
            my={5}
          >
            500 KOII, Each Week
          </Heading>
          <Image w={["0%", "20%"]} src={incentivizedTitleDecor}></Image>
        </Flex>

        <Flex justifyContent={"space-around"} >
          <Text textAlign="center" mt={3}  mx={10} w={{ base: '98%', md: '50%' }}>
            To get your share of the rewards: Install the Koii Desktop Node App and run the Inflation Tracking Task!
          </Text>
        </Flex>

        <Center my={6} flexDirection={"row"} gap={5}>
          <Button
            leftIcon={<DownloadIcon />}
            outline={
              selectedTask === "imatask"
                ? "2px solid #353570"
                : "0px solid #353570"
            }
            onClick={() => {
              setSelectedTask("imatask");
              setActiveDataCache(localCache);
              //Go to this link when the button is clicked
              window.open("https://www.koii.network/node", "_blank");
            }}
            bgColor={"#454580"}
            color={"#BEF0ED"}
            fontSize={["sm", "md"]}
            pl={5}
            pr={5}
          >
            Koii Node
          </Button>
        </Center>

        <Center>
          <Image mt={10} src={miniGears}></Image>
        </Center>
        <Box textAlign="center" mt="5">
          <Text fontWeight="semibold" fontSize="2xl">
            How it works?
          </Text>
          <Text textAlign="center" mb={10}>
            Start earning KOII in 10 minutes.
          </Text>
          <Flex m={10} justifyContent={"center"} gap={"10"} flexWrap="wrap">
            <Box
              width="300px"
              border="2px solid"
              borderColor="white"
              borderRadius="10px"
              padding="4"
            >
              <VStack spacing="4" alignItems="center">
                <Text fontSize="3xl">Step 1</Text>
                <Text textAlign="center">
                  Install the Koii Desktop Node App so you can run web tasks on your computer!
                </Text>
                <Text
                  textAlign="center"
                  mt={3}
                  _hover={{
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open("https://www.koii.network/node", "_blank")
                  }
                >
                  <b>Click here to <u>install Koii.</u></b>
                </Text>
              </VStack>
            </Box>
            <Box
              width="300px"
              border="2px solid"
              borderColor="white"
              borderRadius="10px"
              padding="4"
            >
              <VStack spacing="4" alignItems="center">
                <Text fontSize="3xl">Step 2</Text>
                <Text textAlign="center">
                  After installing the Koii Node App, fund your wallet by authenticating with the faucet. There is a hand with a coin button in the bottom left that links to the faucet.   
                </Text>
                <Text textAlign="center" mt={3}>
                  <b>By using the faucet, you can get up to 8 KOII for free!</b>
                </Text>
              </VStack>
            </Box>
            <Box
              width="300px"
              border="2px solid"
              borderColor="white"
              borderRadius="10px"
              padding="4"
            >
              <VStack spacing="4" alignItems="center">
                <Text fontSize="3xl">Step 3</Text>
                <Text textAlign="center">
                  After claiming your Koii run the Inflation Monitoring task. Find it by clicking "+ Add Task"! While running the task, you will be constantly
                  rewarded with KOII from the app and bonus KOII from the Leaderboard!
                </Text>
                <Text
                  textAlign="center"
                  mt={3}
                  _hover={{
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open("https://docs.koii.network/run-a-node/task-nodes/time-to-node", "_blank")
                  }
                >
                  <b>Click for <u>How to run a task</u></b>
                </Text>
              </VStack>
            </Box>
          </Flex>

          <Box>
            <Box
              m={"auto"}
              boxShadow={"md"}
              width={["90%", "50%", "30%"]}
              mb={10}
            >
              {selectedBox === 0 && (
                <Box mt={5} bg="#FFEE81" borderRadius="lg" p={4}>
                  <Text>
                    Koii allows you to join our decentralized global computer
                    network that aims to take the power back from the
                    monopolies.
                  </Text>

                  <Text
                    textAlign="center"
                    mt={3}
                    _hover={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open("https://www.koii.network/node", "_blank")
                    }
                  >
                    <b>Click here to install Koii.</b>
                  </Text>
                </Box>
              )}

              {selectedBox === 1 && (
                <Box mt={5} bg="#FFEE81" borderRadius="lg" p={4}>
                  <Text>
                    After installing Koii, you need to create a wallet that will
                    store your KOII tokens. By using the faucet, you can get 2
                    KOII for free!
                  </Text>
                </Box>
              )}

              {selectedBox === 2 && (
                <Box mt={5} bg="#FFEE81" borderRadius="lg" p={4}>
                  <Text>
                    After getting your tokens, head over to "Available Tasks"
                    section in Koii by clicking the + button. Find the Twitter
                    Task, provide the required information and use your KOII as
                    a deposit. While running the task, you will be constantly
                    rewarded with KOII (within the app) and bonus KOII (via the
                    Leaderboard)
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        {/*    Second section: Graphs (Leaderboard, and Previous Winners)
         */}
      </Box>

      {
        <Box
        w={{ base: '100%', md: '80%' }}
          mr="auto"
          ml="auto"
          display={["block", "flex"]}
          justifyContent="space-between"
        >
          <Box
            w="100%"
            bg="#353570"
            p="4"
            borderRadius="md"
            mr="auto"
            ml="auto"
            overflowX={'auto'}
          >
            <Heading
              color="#F6E67D"
              textAlign="center"
              fontWeight={600}
              mt={3}
              fontStyle={"Sora"}
              fontSize={"2xl"}
            >
              Current Distribution
            </Heading>
            <Table variant="simple" mt="4" color="#BEF0ED" colorScheme="whiteAlpha">
              <Thead>
                <Tr>
                  <Th color="#F6E67D" textAlign={"center"}>
                    Rank
                  </Th>
                  <Th color="#F6E67D" w="60%" textAlign={"center"}>
                    Stake ID
                  </Th>
                  <Th color="#F6E67D" textAlign={"center"}>
                    Submissions
                  </Th>
                  <Th color="#F6E67D" textAlign={"center"}>
                    KOII Estimate
                  </Th>
                  <Th color="#F6E67D" textAlign={"center"}>
                    Share
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {activeDataCache.results.length > 0 ? (
                  activeDataCache.results
                    .sort(
                      (a, b) =>
                        parseFloat(b.dollarValue) - parseFloat(a.dollarValue)
                    ) // sorting in descending order based on dollarValue
                    .slice(0, 10)
                    .map((data, i) => (
                      <Tr 
                        key={i}
                        style={{
                          backgroundColor: i === 0 ? "#585789" : "transparent",
                          borderColor: i === 0 ? "gold" : "",
                          borderWidth: i === 0 ? "2px" : "0px",
                          borderStyle: i === 0 ? "solid" : "none",
                          color: i === 0 ? "gold" : "none",
                          borderRadius: i === 0 ? "5px" : "0px", // Not working!!
                          boxShadow: i === 0 ? "0px 0px 5px gold" : "none", // add a soft glow
                        }}
                      >
                        <Td fontWeight={"semibold"} textAlign={"center"}>
                          {i + 1}
                        </Td>
                        <Td textAlign={"center"}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleStakeIdClick(data["id"])}
                          >
                            {prettyStakeId(data["id"],"long")}
                          </span>
                        </Td>
                        <Td textAlign={"center"}>{data["count"]}</Td>

                        <Td textAlign={"center"}>{data["dollarValue"]} KOII</Td>
                        <Td textAlign={"center"}>{data["percentage"]}%</Td>

                        {/* If you also want to render other properties like 'percentage' you can add them here. */}
                      </Tr>
                    ))
                ) : (
                  <Tr>
                    <Td>-</Td>
                    <Td>The game has just begun!</Td>
                    <Td>-</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
        </Box>
      }

      {/* Third Section: Pirate Hat Timer */}
    </>
  );
};

export default GameArea;
