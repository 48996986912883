import { Table, Thead, Tbody, Tr, Th, Td, Box, Text } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { prettyStakeId, handleStakeIdClick } from "./helperfunctions.js";

const FirstLeaderboard = ({ localCache }) => {
  return (
    <Box backgroundColor={"#6B5FA5"} borderRadius={10} padding={5} maxWidth={"100%"} overflowX={'auto'}>
      <Table variant="simple" colorScheme="whiteAlpha">
        <Thead>
          <Tr>
            <Th textAlign={"center"} color={"#BEF0ED"} fontSize={"l"}>
              Rank
            </Th>
            <Th
              textAlign={"center"}
              fontSize={"l"}
              color={"#BEF0ED"}
              width="70%"
            >
              Stake ID
            </Th>

            <Th textAlign={"center"} fontSize={"l"} color={"#BEF0ED"}>
              KOII Estimate
            </Th>
            <Th textAlign={"center"} color={"#BEF0ED"} fontSize={"l"}>
              Share
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {localCache.results
            .sort((a, b) => b.percentage - a.percentage) // Sort by descending percentage
            .slice(0, 5)
            .map((data, i) => (
              <Tr key={i}>
                <Td textAlign={"center"}>{i + 1}</Td>
                <Td textAlign={"center"}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleStakeIdClick(data.id)}
                  >
                    {prettyStakeId(data.id, "short")}
                  </span>
                </Td>
                <Td textAlign={"center"}>{data.dollarValue} KOII</Td>
                <Td textAlign={"center"}>{data.percentage}%</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>

      <Text textAlign={"center"} mt={3} color={"#FFEE81"}>
        Each week, 500 KOII is distributed.
      </Text>
    </Box>
  );
};

export default FirstLeaderboard;
