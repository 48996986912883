import React, { useState } from "react";
import {
  Box,
  Center,
  Text,
  InputGroup,
  Input,
  Button,
  Heading,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Divider,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import axios from "axios";
import { positionFinder } from "./helperfunctions.js";
import { set } from "mongoose";

const StatusArea = ({ localCache }) => {
  const [amountOfTickets, setAmountOfTickets] = useState(null);
  const [lastWeekReward, setLastWeekReward] = useState(null);
  const [usdckey, setUsdckey] = useState("");
  const [stakingKey, setStakingKey] = useState("");
  const toast = useToast();

  const checkReward = async (submissionID) => {
    try {
      const response = await fetch("http://localhost:8000/previousweek", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ticketId: submissionID,
        }),
      });

      const data = await response.json();
      setLastWeekReward(data.reward);

      // Handle your response data accordingly
      console.log(data);
    } catch (error) {
      console.error("Error checking reward:", error);
    }
  };

  function downloadFinnie() {
    window.open(
      "https://chrome.google.com/webstore/detail/finnie/cjmkndjhnagcfbpiemnkdpomccnjblmj",
      "_blank"
    );
  }

  function prePositionFinder() {
    const idToSearch = document.getElementById("ticketInput").value;
    console.log(localCache);
    let idSpecificData = positionFinder(localCache, idToSearch);
    if (idSpecificData === null) {
      setAmountOfTickets(false);
      return; // stop further execution
    }
    setAmountOfTickets({
      id: idToSearch,
      submissions: idSpecificData.count,
      dollarValue: idSpecificData.dollarValue,
    });
  }

  return (
    <Box mt={10} >
      <Center>
        <Text mt={5} color={"#353570"} fontWeight="bold" fontSize="2xl">
          Check your status:
        </Text>
      </Center>
      <InputGroup margin={"auto"} width={["60%", "30%"]} mt={5}>
        <Input
          id="ticketInput"
          borderRadius={"100"}
          backgroundColor={"#DCF7F5"}
          placeholder="Your staking ID"
          color={"#353570"}
        />
      </InputGroup>
      <Center>
        <Text color={"#171753"} mt={3} mx={5} fontSize={"md"}>
          You can find it by clicking Settings, then Key Management on Koii.
        </Text>
      </Center>
      <Center mt={3}>
        <Button
          backgroundColor="#445580"
          borderRadius="5px"
          borderColor={"#5ED9D1"}
          color={"#BEF0ED"}
          borderWidth={"2px"}
          boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
          fontSize="18px"
          fontWeight="medium"
          padding="12px 25px"
          _hover={{
            bg: "#325980",
          }}
          leftIcon={<SearchIcon />}
          onClick={prePositionFinder}
        >
          Search
        </Button>
      </Center>
      <Center>
        {amountOfTickets == false && (
          <Box mt={3} color={"black"}>
            This staking ID has no submissions this week.
          </Box>
        )}
        {amountOfTickets && (
          <Box
            mt={10}
            mb={30}
            overflowX="auto"
            w={{ base: '100%', md: '70%' }}
            backgroundColor={"#353570"}
            borderColor="#BEF0ED"
            borderRadius={"xl"}
            color={"#BEF0ED"}
            textAlign={"center"}
            zIndex={2}
          >
            <Heading mt={3} color="#BEF0ED" fontStyle={"Sora"} textShadow={"2xl"}>
              Status Report
            </Heading>
            <Text mt={3} px={5} color={"white"}>
              These values are highly flexible as they are not finalized.
            </Text>

            <Text color={"white"} px={5}>
              Final rewards are calculated by the end of every sunday, and
              deposited to the Koii Node wallets.
            </Text>
            <Table variant="simple" colorScheme="whiteAlpha" >
              <Thead>
                <Tr >
                  <Th color="white"></Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr >
                  <Td>
                    <Text color={"white"}>Staking ID</Text>
                  </Td>
                  <Td>
                    <Text color={"white"}>
                      <b>{amountOfTickets.id}</b>
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color={"white"}>Submissions</Text>
                  </Td>
                  <Td>
                    <Text color={"white"}>
                      <b>{amountOfTickets.submissions}</b>
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color={"white"}>KOII Value</Text>
                  </Td>
                  <Td>
                    <Text color={"white"}>
                      <b>{amountOfTickets.dollarValue} KOII</b>
                    </Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>

            {lastWeekReward && (
              <Box>
                <Heading mt={3} color="#BEF0ED" textShadow={"2xl"}>
                  Last Week's Rewards
                </Heading>
                <Text
                  m={5}
                  color={"#FFEE81"}
                  fontSize={"xl"}
                  fontWeight={"800"}
                >
                  Last week you earned {lastWeekReward} KOII!
                </Text>
              </Box>
            )}
          </Box>
        )}
      </Center>
    </Box>
  );
};

export default StatusArea;
